<template>
  <div>
    <NavbarComponent />
    <h1 class="page-title">Ma Liste de Noël</h1>
    <div v-if="error" class="error">{{ error }}</div>
    <div v-else>
      <!-- Liste de cadeaux -->
      <div class="list-card" v-for="list in maliste" :key="list._id">
        <table class="gift-table">
          <thead>
          <tr>
            <th>Nom</th>
            <th>Prix</th>
            <th>Lien</th>
            <th>Supprimer</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="gift in list.gifts" :key="gift._id">
            <td>{{ gift.name }}</td>
            <td>{{ gift.price ? `${gift.price} €` : 'Non spécifié' }}</td>
            <td>
              <a :href="gift.link" target="_blank" rel="noopener noreferrer">
                Voir le cadeau
              </a>
            </td>
            <td>
              <button @click="deleteGift(gift._id)" class="delete-button">
                ❌
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <!-- Bouton Ajouter un cadeau -->
      <div class="button-container">
        <button @click="openModal" class="add-gift-button">
          Ajouter un cadeau
        </button>
      </div>

      <!-- Modal pour ajouter un cadeau -->
      <div v-if="showModal" class="modal">
        <div class="modal-content">
          <span class="close-button" @click="closeModal">&times;</span>
          <h2>Ajouter un cadeau 🎁</h2>
          <form @submit.prevent="addGift">
            <label for="gift-name">Nom :</label>
            <input id="gift-name" v-model="newGift.name" type="text" required />

            <label for="gift-price">Prix :</label>
            <input id="gift-price" v-model="newGift.price" type="number" min="0" />

            <label for="gift-link">Lien :</label>
            <input id="gift-link" v-model="newGift.link" type="url" />

            <button type="submit">Ajouter 🎉</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import NavbarComponent from "@/components/NavbarComponent.vue";

export default {
  name: "MaListeComponent",
  components: { NavbarComponent },
  data() {
    return {
      maliste: null,
      error: null,
      showModal: false, // Contrôle de la visibilité de la modal
      newGift: {
        name: "",
        price: null,
        link: "",
      }, // Nouveau cadeau à ajouter
    };
  },
  methods: {
    // Ouvrir la modal
    openModal() {
      this.showModal = true;
    },

    // Fermer la modal
    closeModal() {
      this.showModal = false;
    },

    async deleteGift(giftId) {
      try {
        await axios.delete(`https://www.giftlist.ovh/api/gifts/${giftId}`);
        await this.fetchMaListe(); // Rafraîchir la liste après suppression
      } catch (err) {
        console.error("Erreur lors de la suppression du cadeau :", err);
        this.error = "Impossible de supprimer le cadeau.";
      }
    },

    async addGift() {
      try {
        const response = await axios.post("https://www.giftlist.ovh/api/gifts/create", this.newGift);
        const createdGift = response.data.gift;

        const listId = this.maliste[0]._id;
        await axios.post(`https://www.giftlist.ovh/api/lists/${listId}/add-gift`, {
          giftId: createdGift._id,
        });

        await this.fetchMaListe();
        this.closeModal(); // Ferme la modal après l'ajout
        this.newGift = { name: "", price: null, link: "" }; // Réinitialise le formulaire
      } catch (err) {
        console.error("Erreur lors de l'ajout du cadeau :", err);
        this.error = "Impossible d'ajouter le cadeau.";
      }
    },

    async fetchMaListe() {
      try {
        const userId = localStorage.getItem("userId");
        if (!userId) {
          this.error = "Utilisateur non connecté.";
          return;
        }

        const response = await axios.get(`https://www.giftlist.ovh/api/lists/maliste/${userId}`);
        this.maliste = response.data;
      } catch (err) {
        console.error("Erreur lors du chargement des listes :", err);
        this.error = "Erreur lors du chargement de la liste.";
      }
    },
  },
  async created() {
    await this.fetchMaListe();
  },
};
</script>

<style scoped>
/* Page container */
.page-title {
  text-align: center;
  color: white; /* Rouge Noël */
  font-family: 'Cursive', sans-serif;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

/* Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}

.modal-content {
  background-color: #fff;
  padding: 40px;
  border-radius: 12px;
  width: 500px;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: slideUp 0.3s ease-out;
  position: relative;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 28px;
  cursor: pointer;
  color: #d32f2f;
  font-weight: bold;
  transition: color 0.3s;
}

.close-button:hover {
  color: #c2185b;
}

.modal-content input {
  display: block;
  width: 100%;
  margin: 15px 0 ;
  padding: 12px;
  border: 2px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.modal-content input:focus {
  border-color: #388e3c; /* Vert Noël */
  outline: none;
}

.modal-content button {
  padding: 12px 20px;
  background-color: #388e3c; /* Vert Noël */
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  transition: background-color 0.3s;
}

.modal-content button:hover {
  background-color: #2c6e28;
}

.modal-content button:active {
  background-color: #1b5e20;
}

/* Styles des cartes */
.list-card {
  background-color: #fdfdfd;
  border-radius: 12px;
  margin: 20px auto;
  padding: 20px;
  width: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
}

.list-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
}

/* Table style */
.gift-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
  text-align: center;
}

.gift-table th,
.gift-table td {
  border: 1px solid #ddd;
  padding: 10px;
}

.gift-table th {
  background-color: #FF8160; /* Rouge foncé de Noël */
  color: white;
}

.gift-table td {
  background-color: #fafafa;
}

/* Erreur */
.error {
  color: red;
  font-weight: bold;
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

/* Bouton Supprimer */
.delete-button {
  background: none;
  border: none;
  color: #d32f2f;
  font-size: 18px;
  cursor: pointer;
}

.delete-button:hover {
  color: darkred;
}

/* Bouton Ajouter un cadeau centré et en bas */
.button-container {
  text-align: center;
  margin-top: 20px;
}

.add-gift-button {
  padding: 10px 20px;
  background-color: #FEB47B; /* Vert Noël */
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.1rem;
}

.add-gift-button:hover {
  background-color: #FF7E5F;
}
</style>
