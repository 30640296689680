import { createRouter, createWebHistory } from 'vue-router';
import LoginComponent from '../components/LoginComponent.vue';
import ListesComponent from '../components/ListesComponent.vue';
import MaListeComponent from "@/components/MaListeComponent.vue";

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginComponent,
  },
  {
    path: '/listes',
    name: 'Listes',
    component: ListesComponent,
    meta: { requiresAuth: true }, // Nécessite une connexion
  },
  {
    path: '/maliste',
    name: 'maListes',
    component: MaListeComponent,
    meta: { requiresAuth: true }, // Nécessite une connexion
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Vérification globale avant chaque navigation
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('userId'); // Vérifie si un ID utilisateur est stocké
  if (to.meta.requiresAuth && !isAuthenticated) {
    // Si la route nécessite une authentification et que l'utilisateur n'est pas connecté
    next({ name: 'Login' });
  } else {
    next(); // Sinon, continue
  }
});

export default router;
