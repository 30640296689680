<template>
  <div>
    <NavbarComponent />
    <h1 class="page-title">Listes disponibles</h1>
    <div v-if="error" class="error">{{ error }}</div>
    <div v-else>
      <div class="list-card" v-for="list in otherLists" :key="list._id">
        <div class="card-header">
          <h2>{{ list.name }}</h2>
        </div>
        <table class="gift-table">
          <thead>
          <tr>
            <th>Nom</th>
            <th>Prix</th>
            <th>Lien</th>
            <th>Réservé par</th>
            <th>Réserver</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="gift in list.gifts" :key="gift._id">
            <td>{{ gift.name }}</td>
            <td>{{ gift.price ? `${gift.price} €` : 'Non spécifié' }}</td>
            <td>
              <a :href="gift.link" target="_blank" rel="noopener noreferrer">
                Voir le cadeau
              </a>
            </td>
            <td>
              {{ gift.reservedBy && gift.reservedBy.length > 0
                ? gift.reservedBy.map(user => user.username).join(', ')
                : 'Aucun' }}
            </td>
            <td>
              <input
                  type="checkbox"
                  :checked="isReservedByCurrentUser(gift.reservedBy)"
                  @change="toggleReservation(gift)"
              />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import NavbarComponent from "@/components/NavbarComponent.vue";

export default {
  name: "ListesComponent",
  components: {NavbarComponent},
  data() {
    return {
      otherLists: [],
      error: null,
    };
  },
  methods: {
    /**
     * Vérifie si le cadeau est réservé par l'utilisateur connecté.
     * @param {Array} reservedBy - Liste des objets utilisateurs ayant réservé ce cadeau.
     * @returns {boolean} - Vrai si l'utilisateur connecté a réservé ce cadeau.
     */
    isReservedByCurrentUser(reservedBy) {
      const userId = localStorage.getItem("userId");
      return reservedBy.some(user => user._id === userId);  // Comparaison avec _id des utilisateurs
    },

    /**
     * Gère l'ajout ou la suppression de l'utilisateur connecté à reservedBy.
     * @param {Object} gift - L'objet cadeau.
     */
    async toggleReservation(gift) {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        this.error = "Utilisateur non connecté.";
        return;
      }

      const action = this.isReservedByCurrentUser(gift.reservedBy) ? "remove" : "add";

      try {
        // Requête PATCH pour ajouter ou supprimer l'utilisateur
        await axios.patch(`https://www.giftlist.ovh/api/gifts/${gift._id}/reserve`, {
          userId,
          action,
        });

        // On reprend les listes
        this.fetchLists();
      } catch (err) {
        console.error("Erreur lors de la mise à jour de la réservation :", err);
        this.error = "Erreur lors de la mise à jour de la réservation.";
      }
    },

    /**
     * Récupère les listes d'autres utilisateurs via l'API.
     */
    async fetchLists() {
      try {
        const userId = localStorage.getItem("userId");
        if (!userId) {
          this.error = "Utilisateur non connecté.";
          return;
        }

        const response = await axios.get(
            `https://www.giftlist.ovh/api/lists/all-except/${userId}`
        );
        this.otherLists = response.data;
      } catch (err) {
        this.error = "Erreur lors du chargement des listes.";
        console.error(err);
      }
    },
  },
  async created() {
    await this.fetchLists();
  },
};
</script>

<style scoped>
/* Page container */
.page-title {
  text-align: center;
  color: white;
  font-family: 'Cursive', sans-serif;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

/* List container */
.list-card {
  background-color: #f5f5f5;
  border-radius: 12px;
  margin: 20px auto;
  padding: 20px;
  width: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
}

.list-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
}

/* Header style */
.card-header {
  background-color: #FF8160;
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 15px;
}

.card-header h2 {
  font-family: 'Arial', sans-serif;
  font-size: 1.8rem;
  margin: 0;
}

/* Table style */
.gift-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
  text-align: center;
}

.gift-table th,
.gift-table td {
  border: 1px solid #ddd;
  padding: 10px;
}

.gift-table th {
  background-color: #FF8160; /* Rouge Noël */
  color: white;
}

.gift-table td {
  background-color: #f9f9f9;
}

/* Error message */
.error {
  color: red;
  font-weight: bold;
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 20px;
}
</style>
