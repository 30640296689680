<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>


export default {
  name: "App",
};

</script>

<style>
/* Styles globaux */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #FF996D;
}
</style>
