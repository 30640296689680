<template>
  <nav class="navbar">
    <ul>
      <li>
        <button @click="navigateTo('/maliste')">Ma Liste</button>
      </li>
      <li>
        <button @click="navigateTo('/listes')">Autres Listes</button>
      </li>
    </ul>
    <button @click="logout" class="logout-button">Déconnexion</button>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      userId: localStorage.getItem("userId"), // Charge l'état initial
    };
  },
  methods: {
    /**
     * Navigue vers une page donnée.
     * @param {string} path - Le chemin vers lequel naviguer.
     */
    navigateTo(path) {
      this.$router.push(path);
    },
    /**
     * Déconnexion de l'utilisateur.
     */
    logout() {
      localStorage.clear(); // Supprime les données utilisateur
      this.updateUserId(); // Met à jour l'état local
      this.$router.push('/'); // Redirige vers la page de connexion
    },
    /**
     * Met à jour userId lorsque le localStorage est modifié.
     */
    updateUserId() {
      this.userId = localStorage.getItem("userId");
    },
  },
  mounted() {
    // Écoute les modifications du localStorage
    window.addEventListener("storage", this.updateUserId);
    // Vérifie à nouveau l'état utilisateur au cas où localStorage change après le montage
    this.updateUserId();
  },
  beforeUnmount() {
    // Nettoie l'écouteur d'événement lors du démontage
    window.removeEventListener("storage", this.updateUserId);
  },
};
</script>

<style scoped>
/* Barre de navigation */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, #ff7e5f, #feb47b); /* Dégradé doux */
  padding: 15px 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar ul {
  list-style-type: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.navbar li {
  display: inline;
}

.navbar button {
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.navbar button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: scale(1.05);
}

.logout-button {
  background-color: #e74c3c; /* Rouge pour la déconnexion */
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 700;
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.logout-button:hover {
  background-color: #c0392b; /* Couleur plus foncée pour la déconnexion au survol */
  transform: scale(1.05);
}
</style>
