<template>
  <div class="login-container">
    <form @submit.prevent="login">
      <label for="username">Nom d'utilisateur</label>
      <input v-model="username" type="text" id="username" required />
      <label for="password">Mot de passe</label>
      <input v-model="password" type="password" id="password" required />
      <button type="submit">Se connecter</button>
    </form>

    <!-- Modal d'erreur -->
    <div v-if="error" class="error-modal">
      <div class="modal-content">
        <p class="error-message">{{ error }}</p>
        <button class="ok-button" @click="closeErrorModal">OK</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      password: '',
      error: '', // Message d'erreur
    };
  },
  methods: {
    async login() {
      try {
        // Envoyer la requête de connexion
        const response = await axios.post('https://www.giftlist.ovh/api/users/login', {
          username: this.username,
          password: this.password,
        });

        const userId = response.data.id; // Récupérer l'ID utilisateur du backend

        // Stocker l'ID utilisateur pour la session
        localStorage.setItem('userId', userId); // Stocke l'ID utilisateur dans localStorage
        this.$router.push('/maliste'); // Redirige vers la page d'accueil
      } catch (err) {
        this.error = 'Identifiants incorrects.'; // Afficher un message d'erreur
        console.error(err);
      }
    },

    closeErrorModal() {
      this.error = ''; // Fermer la modal en réinitialisant l'erreur
    },
  },
};
</script>

<style scoped>
.login-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('@/assets/fondNoel.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
}

label {
  font-size: 1.1rem;
}

input {
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 200px;
}

button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

/* Modal d'erreur */
.error-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  text-align: center;
}

.error-message {
  font-weight: bold; /* Texte en gras */
  margin-bottom: 20px;
  color: red;
}

.ok-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.ok-button:hover {
  background-color: #45a049;
}
</style>
